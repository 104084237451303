import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

/* Componente para crear el cuadrado de cada artículo en la página de entradas
  article.title: Título del artículo
  article.heroImage.fluid: Imagen del artículo
  article.slug: Slug del artículo (URL)
  article.publishDate: Fecha de publicación del artículo
*/
export default ({ article }) => (
  <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:w-1/2 lg:w-1/3 xl:w-1/4">
    <div className="bg-gradient-to-r from-blue-700 to-blue-900 rounded-lg overflow-hidden shadow">
      <Img
        className="w-full object-cover object-center"
        alt={article.title}
        fluid={article.heroImage.fluid}
      />
      <div className="p-4">
        <Link
          className="block text-gray-200 hover:text-gray-900 font-semibold mb-2 text-lg md:text-base lg:text-lg"
          to={`/entradas/${article.slug}`}
        >
          {article.title}
        </Link>
        <div className="text-white text-sm leading-relaxed block md:text-xs lg:text-sm">
          <small>Publicado el {article.publishDate}</small>
        </div>
      </div>
    </div>
  </div>
)
