import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ArticleList from '../components/article-list'

/* página de entradas
  site.siteMetadata.title: Título del sitio
  site.siteMetadata.description: Descripción del sitio
  site.siteMetadata.url: URL del sitio
  
  El componente Helmet sirve para añadir metadatos a la página

  GraphQL: Consulta para obtener los datos de los artículos (Imagen, título, slug y fecha de publicación)

  Los metadatos están especificados en gatsby-config.js
*/

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet title={`Entradas | ${siteTitle}`} />
        <section className="bg-white p-6">
          <div className="px-2">
            <h1 className="bg-blue-400 pattern text-2xl font-semibold text-white mb-6 py-3 mx-auto text-center">
              Todas las entradas
            </h1>
            <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1">
              {posts.map(({ node }) => {
                return <ArticleList article={node} />
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

/**
 * Consulta GraphQL para obtener los datos de la página
 * allContentfulBlogPost: consulta para obtener los datos de las entradas. Esta consulta es propia del plugin de Contentful
 */

export const pageQuery = graphql`
         query BlogIndexQuery {
           site {
             siteMetadata {
               title
             }
           }
           allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
             edges {
               node {
                 title
                 slug
                 publishDate(formatString: "D [de] MMMM [del] YYYY", locale: "es-es")
                 heroImage {
                   fluid(maxWidth: 640, maxHeight: 480) {
                     ...GatsbyContentfulFluid_tracedSVG
                   }
                 }
               }
             }
           }
         }
       `
